
.contact {
  background-color: #011f4b;
  display: flex;
  
  .contactLeft {
    flex: 0.1;
    background-color: #011f4b;
  }
  .contactCenter {
    flex: 0.8;
    background-color: #011f4b;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    margin: 0 auto;
    margin-top: 300px;
    // width: 80vw;
    height:400px;
    max-width: 800px;
    text-align: center;
    position: relative;
    
    justify-content: center;
    align-items: center;
    
  }
  h1{
    color: white;
    font-size:80px;
    margin-bottom: 130px;
    
  }

  .email-link{
    color: #e3a6f3;
    background-color: transparent;
    border: 1px solid #e3a6f3;
    border-radius: 5px;
    padding: 1.25rem 1.75rem;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    margin-top: 100px;
  }
  .contactRight{
    flex: 0.1;
  }
}

