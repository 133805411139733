.app {
    height: 100vh;
    overflow: hidden;
    .sections {
      width: 100%;
      height: calc(100vh - 100px);
      // background-color: pink;
      position: relative;
      top: 100px;
      scroll-behavior: smooth;
      scroll-snap-type: y mandatory;
      // scrollbar-width: none;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
  
      > * {
        width: 100vw;
        height: calc(100vh - 100px);
        scroll-snap-align: start;
        margin:0 auto;
        
      }
    }
  }
  