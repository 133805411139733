.about {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #011f4b;
  .aboutLeft {
    flex: 0.1;
    // background-color: #011f4b;
  }
  .aboutCenter {
    display: flex;
    width:60%;
    margin: 0 auto;
    color:white;
    justify-content: center;
    align-items: center;
    flex-direction:column-reverse;
    
    .aboutContainer {
      display: flex;
      flex: 0.6;
      justify-content: center;
      align-items: center;
      width:auto;
      height: auto;
      // background-color: #011f4b;
      .aboutContent{
        width: 100%; 
        h2 {
          
          color:#e3a6f3;
        }
       
      }
      p {
        margin-top: 15px;
        font-size: 20px;
        color:#8892b0;
      }
      ul {
        margin-top: 20px ;
        margin-left: -12px;
        padding: 0;
        li {
          float: left;
          margin-left: 10px;
          margin-right: 35px;
          color:#8892b0;
         
        }
        li:before {
          content: "▹";
          color:#e3a6f3;
          margin-right: 5px;
        }
        
      }
    }
    .aboutImage {
      margin-bottom: 20px;
      display: flex;
      flex: 0.4;
      justify-content: center;
      height: 528px;
      img {
        width: 200px;
        height:200px;
        border-radius: 50%;
        object-fit:fill;
      }
     
    }
  }
    
  .aboutRight{
    flex: 0.1;
    // background-color: #011f4b;
  }
}

@media screen and (min-width:941){
  
  
}
@media screen and (max-width: 940px) {
  .aboutCenter{
    display: flex;
    flex-direction: column;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}
