.social_media {
    // margin: 518px 48px 0;
    position: relative;

    
}


.social_media_items {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    list-style: none;
    position: fixed;
    bottom: 0px;
    right: auto;
    left: 35px;
    z-index: 10;
    
}

.icon {
    color: white;
    margin-bottom: 40px;
    width: 20px;
    height: 20px;
}



.social_media_items::after {
    content: "";
    display: block;
    width: 1px;
    height: 190px;
    margin: 0 auto;
    background-color: white;
}


@media screen and (max-width: 940px) {
    .social_media {
        display: none;
    }
}
