.topbar {
  background-color: #011f4b;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: 3;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  transition: all 1s ease;
  color: #e8eaee;
  cursor: pointer;

  .topLogo {
    img {
      width: 124px;
      height: 124px;
      margin-left: 30px;

    }
    // margin-left: 30px;
    // padding-left: 20px;
    // width: 50px;
    // height: 50px;
  }

  .topRight {
    display: flex;
  }
  
  &.active {
    background-color: #011f4b;
    color: white;

    .hamburger {
      span {
        &:first-child{
          background-color: #e3a6f3;
          transform: rotate(45deg);
        }
        &:nth-child(2){
          opacity: 0;
        }
        &:last-child{
          background-color: #e3a6f3;
          transform: rotate(-45deg);
        }
      }
    }
  }
}


.topMenu {
  display: flex;
  .topItem {
    padding: 1rem;
    
    
  }
  
  
  
  .l1 {
    margin-right: 30px;
  }
  li a {
    text-decoration: none;
    color: #ccd6f6;
  }

  li a:hover {
    color:#e3a6f3;
  }
}


.topButton .resumeButton {
  text-decoration: none;
  display: flex;
  color: #e3a6f3;
  border: 1px solid #e3a6f3;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  
  justify-content: center;
  align-items: center;
  
}
.topButton .resumeButton:hover, 
.topButton .resumeButton:active {
  opacity: 0.8

}


.hamburger {
  width: 32px;
  height: 25px;
  // display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 30px;
  span {
    width: 100%;
    height: 3px;
    background-color: #e3a6f3;
    transform-origin: left;
    transition: all 2s ease;
  }
}

@media screen and (min-width:941){
  .hamburger {
    display: none;
  }
  
}
@media screen and (max-width: 940px) {
  .topMenu, .topButton {
    display: none;
  }
  
  .hamburger {
    display: flex;
    padding-right:20px;
  }
}

